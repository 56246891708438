import React, { FC, memo } from 'react';

import { Pdf } from '../../components/Pdf/Pdf';
import DocumentModel from '../../models/DocumentModel/DocumentModel';

const DocumentContainer: FC = memo(() => {
  const { documentState, closePdf } = DocumentModel();

  const ownerInfo = {
    owner: 'ИП Першин Сергей Сергеевич',
    inn: '744409672638',
    ogrn: '324745600078966',
    licenseNumber: '',
    orderNumber: '',
    address: {
      registrationAddress: '',
      factAddress: '',
    },
  };
  const style = {
    containerStyle: {
      paddingTop: 0,
    },
    headerStyle: {},
    buttonStyle: {},
  };

  return (
    <Pdf
      theme="sobank"
      origin={window.location.origin}
      closeHandler={closePdf}
      name="Онлайн-банк"
      secondName="Онлайн-банк"
      {...ownerInfo}
      {...documentState}
      {...style}
    />
  );
});

export default DocumentContainer;
