import { DomainsName, getCurrentDomainName } from './helpers';

export const currentDomain: DomainsName = getCurrentDomainName();

interface FooterFields {
  ip_owner: string;
  ip_number: string;
  email: string;
  phone: string;
  address: string;
}

export type DomainProps<T> = { [key in DomainsName]: T };

type DomainDataStoreProps = {
  footer: DomainProps<FooterFields>;
  yandexMetrics: DomainProps<string>;
  googleMetrics: DomainProps<string>;
};

export const DomainDataStore: DomainDataStoreProps = {
  yandexMetrics: {
    dengissite: '',
    zaiman: '',
    migzaym: '',
    zaimactive: '',
  },
  googleMetrics: {
    dengissite: 'GTM-P675BJ3',
    zaiman: 'GTM-TLWRW4R',
    migzaym: 'GTM-54S7MCD',
    zaimactive: 'GTM-TMWZPD4',
  },
  footer: {
    dengissite: {
      ip_owner: 'Сорокина АВ',
      ip_number: '773271322106',
      email: 'dengis.site',
      phone: '+7 (495) 537-83-27',
      address: '115088, Москва, Шарикоподшипниковская ул., 34',
    },
    zaiman: {
      ip_owner: 'Маштаков ЕС ',
      ip_number: '744507054044',
      email: 'zaim-an.ru',
      phone: '',
      address: '455038, Магнитогорск, Ленина, 130 офис 214',
    },
    migzaym: {
      ip_owner: 'Белобородов АП',
      ip_number: '774398703827',
      email: 'migzaym.com.ru',
      phone: '+7 (495) 874-11-78',
      address: '127566, Москва, Высоковольтный пр., 1к6',
    },
    zaimactive: {
      ip_owner: 'Веселов ВЕ',
      ip_number: '772372940160',
      email: 'zaim-active.com.ru',
      phone: '+7 (495) 431-17-64',
      address: '127247, Москва, Дмитровское ш., 99 корпус 1',
    },
  },
};
