import React from 'react';

import { WidgetProvider } from '@ca-actual-projects/bca-widget';
import ReactDOM from 'react-dom/client';
import 'styles/reset.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { MediaProvider } from './context/MediaProvider';

import { App } from 'pages/App';
import { store } from 'store';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <WidgetProvider>
      <Provider store={store}>
        <MediaProvider>
          <App />
        </MediaProvider>
      </Provider>
    </WidgetProvider>
  </BrowserRouter>,
);
