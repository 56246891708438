import bar from '../../../assets/icons/advantages/bar.svg';
import card from '../../../assets/icons/advantages/card.svg';
import doc from '../../../assets/icons/advantages/doc.svg';
import pass from '../../../assets/icons/advantages/pass.svg';

type AdvantagesCardDataType = {
  icon: string;
  text: string;
  boldText?: string;
};

export const AdvantagesCardArray: AdvantagesCardDataType[] = [
  {
    icon: doc,
    text: 'Автоматическое одобрение первой заявки',
  },
  {
    icon: pass,
    text: 'Получение по паспорту',
  },
  {
    icon: card,
    text: 'Онлайн на карту',
  },

  {
    icon: bar,
    text: 'Самый лояльный скоринг',
  },
];
