import React, { FC } from 'react';

import { Widget } from '@ca-actual-projects/bca-widget';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Advantages } from '../components/Advantages';
import { AtUs } from '../components/AtUs';
import { Banner } from '../components/Banner';
import { Footer } from '../components/Footer';
import { HowItWorks } from '../components/HowItWorks';
import { IssueMfo } from '../components/IssueMFO';
import { OurPartners } from '../components/OurPartners';
import { Question } from '../components/Question';
import DocumentContainer from '../containers/DocumentContainer/DocumentContainer';
import DocumentModel from '../models/DocumentModel/DocumentModel';

import s from './App.module.scss';

import logo from 'assets/logo/test-logo.png';
import { MFO } from 'components/MFO';

export const App: FC = () => {
  const navigate = useNavigate();
  const { openPdf } = DocumentModel();
  return (
    <div>
      <div className={s.wrapper}>
        <main className={s.page}>
          <Routes>
            <Route
              path="*"
              element={
                <div className={s.layout}>
                  <Banner />
                  <Advantages />
                  <MFO />
                  <HowItWorks />
                  <OurPartners />
                  <AtUs />
                  <IssueMfo />
                  <Question />
                </div>
              }
            />
            <Route path="/form" element={null} />
          </Routes>

          <Widget
            logo={logo}
            ymId={85610464}
            formPathname="form"
            afterAuth={() => {
              navigate('/form');
            }}
            onLogoClick={() => {
              navigate('/');
            }}
            pdfOpen={openPdf}
            short
            theme="orange"
            backUrl="https://prod.onbank.online"
          />
          <Footer />
          <DocumentContainer />
        </main>
      </div>
    </div>
  );
};
