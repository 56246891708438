import React from 'react';

import { Button } from '../../../shared/Button';
import s from '../style/Banner.module.scss';

type BannerTextBlockPropsType = {
  className?: string;
  subtitle: boolean;
  button: boolean;
};

export const BannerTextBlock: React.FC<BannerTextBlockPropsType> = ({
  className,
  subtitle,
  button,
}) => {
  const openModalHandler = () => {
    window.open(window.location.href);
    window.location.replace(`https://zaim-exp.ru/${window.location.search}`);
    localStorage.setItem('isReplace', 'true');
  };

  return (
    <div className={className ?? className}>
      <div className={s.banner__title}>
        Автоодобрение по номеру телефона до 30 000 рублей
      </div>
      {subtitle && <div className={s.banner__subtitle}>Первый заём 0%</div>}
      {button && (
        <Button className={s.button__banner} onClick={openModalHandler}>
          Оформить заявку
        </Button>
      )}
    </div>
  );
};
