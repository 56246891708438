import React, { useContext } from 'react';

import rocket from '../../assets/icons/mfo-type/rocket.svg';
import { MediaContext } from '../../context/MediaProvider';
import com from '../../styles/common.module.scss';

import s from './style/MFO.module.scss';

export const MFO: React.FC = () => {
  const tablet = useContext(MediaContext);

  return (
    <section className={`${tablet ? com.container : ''}`}>
      <div className={s.card}>
        <h2 className={com.title__h2_landing}>Второй заём</h2>
        <div className={`${s.card__items} ${!tablet && com.no_scroll_block}`}>
          <article className={s.item}>
            <div className={s.item__body}>
              <p className={s.item__body__text}>
                Оплатите первый заём без просрочек и получите второй на более выгодных
                условиях
              </p>
              <div className={s.item__body__content}>
                <ul className={s.item__body__content__list}>
                  <li>до 500 000 ₽</li>
                  <li>до 365 дней</li>
                  <li>от 12% в год</li>
                </ul>
                <div className={s.item__body__content__icon}>
                  <img src={rocket} alt="card-icon" />
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};
