import React, { useCallback } from 'react';

import DocumentModel from '../../models/DocumentModel/DocumentModel';
import com from '../../styles/common.module.scss';

import s from './style/Footer.module.scss';

import { Accordion } from 'shared/Accordion';
import { currentDomain } from 'themes';

export const Footer: React.FC = () => {
  const { openPdf } = DocumentModel();

  const ownerInfo = {
    owner: 'ИП Першин Сергей Сергеевич',
    inn: '744409672638',
    ogrn: '324745600078966',
    licenseNumber: '',
    orderNumber: '',
    address: {
      registrationAddress: '',
      factAddress: '',
    },
  };

  const openOfferHandler = useCallback(() => {
    openPdf('offer');
  }, []);

  const openPoliticsHandler = useCallback(() => {
    openPdf('politics');
  }, []);

  const openProcessingHandler = useCallback(() => {
    openPdf('processing');
  }, []);

  const isZaimActive = currentDomain === 'zaimactive';
  const zaimActiveTextContent = (
    <div>
      <span className={`${s.info__text} ${s.paddingTop}`}>
        Лицензия на хранение и обработку персональных данных № 74-18-004739 Приказ № 212
        от 23.11.2018. Оставляя заявку, вы соглашаетесь на обработку персональных данных,
        получение информации, получение доступа к кредитной истории, использование аналога
        собственноручной подписи, оферту, политика относительно обработки персональных
        данных, форма согласия на обработку персональных данных.
      </span>

      <span className={`${s.info__text} ${s.pb__clear}`}>
        ИП Веселов ВЕ ИНН 772372940160 ОГРН 319774600531620
      </span>
      <span className={`${s.info__text} ${s.pb__clear}`}>
        Email: support@zaim-active.com.ru
      </span>
      <span className={`${s.info__text} ${s.pb__clear}`}>+7 (495) 431-17-64</span>
      <span className={s.info__text}>
        Адрес 127247, Москва, Дмитровское ш., 99 корпус 1
      </span>
    </div>
  );
  return (
    <div className={s.footer}>
      <div className={`${com.container} ${com.container__footer}`}>
        <div className={s.info}>
          <Accordion
            title="Информация для клиентов"
            textContent={isZaimActive && zaimActiveTextContent}
          >
            <>
              <span className={s.info__text}>
                Бесплатный сервис{' '}
                <a href={window.location.origin}>{window.location.origin}</a> позволяет
                подать заявку и узнать окончательное решение сразу от нескольких МФО,
                после чего останется выбрать подходящее предложение и получить его удобным
                способом: на карту или наличными.
                <br />
                Информацию о процентных ставках по договорам кредитования с физическими
                лицами можно узнать у каждого Партнера индивидуально на официальном сайте
                Партнера или в офисах Партнера.
              </span>
              <span className={s.info__text}>
                Заполняя заявку на{' '}
                <a href={window.location.origin}>{window.location.origin}</a> вы даете
                свое согласие на{' '}
                <button
                  onClick={openProcessingHandler}
                  type="button"
                  className={s.linkButton}
                >
                  обработку персональных данных
                </button>
                ,{' '}
                <button
                  onClick={openPoliticsHandler}
                  type="button"
                  className={s.linkButton}
                >
                  Политику относительно обработки персональных данных
                </button>{' '}
                и принимаете{' '}
                <button onClick={openOfferHandler} type="button" className={s.linkButton}>
                  оферту
                </button>
                .
              </span>
              <span className={s.info__text}>
                Оставаясь на сайте{' '}
                <a href={window.location.origin}>{window.location.origin}</a> и продолжая
                работу с ним Вы даете согласие на использование файлов cookie.
              </span>
              <span className={s.info__text}>
                Сайт <a href={window.location.origin}>{window.location.origin}</a>{' '}
                оказывает исключительно информационные услуги подбора кредитных
                предложений и не несет ответственности за правовые отношения между МФО
                (Партнером) и Пользователем сайта. Услуги на сайте оказывает{' '}
                {ownerInfo.owner} ОГРН {ownerInfo.ogrn}
              </span>
              <span className={s.info__text}>
                Copyright ©2024 Все права защищены. Возрастное ограничение 18+
              </span>
            </>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
